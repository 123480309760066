<template>
  <v-layout row wrap align-content-start fill-height class="pa-4">
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-text>
          <span class="headline">Add Patient</span>
          <v-container grid-list-md>
            <v-form ref="form" lazy-validation>
              <v-layout wrap >
                <v-flex xs12>
                  <label v-bind:style="{ display: nameLabel, color: 'red' }">* Required</label>
                  <v-text-field 
                    v-model="editedItem.name"
                    label="Name">
                  </v-text-field>
                </v-flex>
                <v-flex xs12>
                  <label v-bind:style="{ display: nationIdLabel, color: 'red' }">* Required</label>
                  <v-text-field 
                    v-model="editedItem.nationId"
                    label="National ID">
                  </v-text-field>
                </v-flex>
                <v-flex xs12>
                  <label v-bind:style="{ display: noLabel, color: 'red' }">* Required</label>
                  <v-text-field 
                    v-model="editedItem.no"
                    label="Medical Record">
                  </v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-select
                    label="Gender"
                    :items="[ 
                      { text: 'Male', value: '1' }, 
                      { text: 'Female', value: '0' } 
                    ]"
                    v-model="editedItem.gender" >
                  </v-select>
                </v-flex>
                <v-flex xs12>
                  <label v-bind:style="{ display: birthdayLabel, color: 'red' }">* Required</label>
                  <v-menu
                    ref="menu"
                    v-model="addCalendarMenu"
                    :close-on-content-click="false"
                    :return-value.sync="editedItem.birthday"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    full-width>
                      <v-text-field
                        v-model="editedItem.birthday"
                        slot="activator"
                        label="Birthday"
                        persistent-hint
                        prepend-icon="event"
                        readonly
                        :value="editedItem.birthday">
                      </v-text-field>
                    <v-date-picker v-model="editedItem.birthday" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="addCalendarMenu = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(editedItem.birthday)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-flex>
              </v-layout>                  
            </v-form>
            <v-chip class="err-msg" v-if="errorMessage" color="red" text-color="white">
              <v-icon left>info</v-icon>
              {{ errorMessage }}
            </v-chip>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" flat @click="() => this.dialog = false">Cancel</v-btn>
          <v-btn color="blue darken-1" flat @click="addPatient">Add</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-flex xs12 class="page-head-container mb-3">
      <v-flex xs12 class="mb-4 page-head-container">
        <h1 class="headline font-weight-light">Patient List</h1>
      </v-flex>

      <v-flex xs12 class="search-container">
        <v-layout>
          <v-flex xs3>
            <h4>Medical Record</h4>
            <v-text-field
              v-model="condition.no"
              :rules="xssRules"
              hide-details
            ></v-text-field>
          </v-flex>
          <v-flex xs3>
            <h4>{{ $t('patient.name') }}</h4>
            <search-patient-input
              @selected="selectPatient"
              :name="condition.name"
            ></search-patient-input>
          </v-flex>
          <v-flex xs3>
            <h4>Nation ID</h4>
            <v-text-field
              v-model="condition.nationId"
              :rules="xssRules"
              hide-details
            ></v-text-field>
          </v-flex>
          <v-flex xs3>
            <h4>Gender</h4>
            <v-select
              :items="[ 
                { text: 'Not Specific', value: null },
                { text: 'Male', value: '1' }, 
                { text: 'Female', value: '0' } 
              ]"
              v-model="condition.gender"
            ></v-select>
          </v-flex>
          <v-flex xs2>
            <h4>Birthday</h4>
            <date-picker-input
              :date="condition.birthday"
              @selected="(date) => condition.birthday = date"
            ></date-picker-input>
          </v-flex>
          <v-flex xs1>
            <v-btn
              @click="resetSearch()">
                Reset
            </v-btn>
          </v-flex>
          <v-flex xs1>
            <v-btn
              elevation="2"
              color="info"
              @click="search"
            > Search</v-btn>
          </v-flex>
        </v-layout>
      </v-flex>

      <v-flex xs12>
        <template>
          <v-data-table
            v-if="getPatientSearchResult.length > 0"
            :headers="headers"
            :items="getPatientSearchResult"
            :pagination.sync="pagination"
            hide-actions
            class="my-4"
            :disable-initial-sort=true >

            <template slot="headers" slot-scope="props">
              <tr>
                <th
                  v-for="header in headers"
                  :key="header.text"
                  :class="['column sortable', pagination.order ? 'desc' : 'asc', header.value === pagination.title ? 'active' : '']"
                  @click="changeSort(header.value)"
                >
                  <v-icon small>arrow_upward</v-icon>
                  {{ header.text }}
                </th>
              </tr>
            </template>


            <template slot="items" slot-scope="props">
              <tr @click="clickItem(props.item)">
                <td>
                  <v-edit-dialog
                    :return-value.sync="props.item.no"
                    lazy
                    @save="updatePatient(props.item.id, 'no', tempItem.no)"
                  > {{ props.item.no }}
                    <v-text-field
                      slot="input"
                      v-model="tempItem.no"
                      label="Edit"
                      single-line
                    ></v-text-field>
                  </v-edit-dialog>
                </td>
                <td>
                  <v-edit-dialog
                    :return-value.sync="props.item.name"
                    lazy
                    @save="updatePatient(props.item.id, 'name', tempItem.name)"
                  > {{ props.item.name }}
                    <v-text-field
                      slot="input"
                      v-model="tempItem.name"
                      label="Edit"
                      single-line
                    ></v-text-field>
                  </v-edit-dialog>
                </td>
                <td>
                  <v-edit-dialog
                    :return-value.sync="props.item.nationId"
                    lazy
                    @save="updatePatient(props.item.id, 'nationId', tempItem.nationId)"
                  > {{ props.item.nationId }}
                    <v-text-field
                      slot="input"
                      v-model="tempItem.nationId"
                      label="Edit"
                      single-line
                    ></v-text-field>
                  </v-edit-dialog>
                </td>                
                <td>
                  <v-select
                    :items="[ { text: 'Male', value: '1' }, { text: 'Female', value: '0' } ]"
                    v-model="props.item.gender" @change="updatePatient(props.item.id,'gender',$event)"
                  ></v-select>
                </td>
                <td v-if="birthdayMenus !== null">
                  <v-menu
                    v-model="birthdayMenus[props.index]"
                    :nudge-right="40"
                    :close-on-content-click="false"
                    lazy
                    transition="scale-transition"
                    offset-y
                    full-width
                    max-width="290px"
                    min-width="290px"
                  >
                    <div slot="activator">
                      {{ props.item.birthday }}
                    </div>
                    <v-date-picker 
                      v-model="props.item.birthday" 
                      no-title 
                      @change="updatePatient(props.item.id,'birthday',props.item.birthday)">
                    <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="searchCalendarMenu = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(props.item.birthday)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </td>
              </tr>
            </template>
          </v-data-table>
        </template>

        <div class="text-center">
          <v-pagination
            v-model="getPatientSearchInfo.page"
            :length="getPatientSearchInfo.total_page"
            :total-visible="7"
            @input="clickPage"
          ></v-pagination>
        </div>
      </v-flex>
    </v-flex>

    <v-btn icon fixed bottom right fab color="primary" @click="() => this.dialog = true">
      <v-icon>add</v-icon>
    </v-btn>

    <v-snackbar
      v-model="snackbar"
      bottom
      :timeout="timeout"
      color="red"
    >
      {{ message }}
    </v-snackbar>
  </v-layout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import DatePickerInput from './components/DatePickerInput.vue'
import SearchPatientInput from './components/SearchPatientInput.vue';

export default {
  components: { 
    DatePickerInput,
    SearchPatientInput 
  },
    name: 'AdminPatientList',
    data() {
      return {
        addCalendarMenu: false,
        dialog: false,
        nameLabel: 'none',
        nationIdLabel: 'none',
        noLabel: 'none',
        birthdayLabel: 'none',
        pagination: {
          rowsPerPage: -1,
          title:'',
          order:false
        },
        headers: [
          {
            text: 'Medical Record',
            align: 'left',
            value: 'no'
          },
          {
            text: 'Name',
            align: 'left',
            value: 'name'
          },
          {
            text: 'Nation ID',
            align: 'left',
            value: 'nationId'
          },
          {
            text: 'Gender',
            align: 'left',
            value: 'gender'
          },
          {
            text: 'Birthday',
            align: 'left',
            value: 'birthday'
          }
        ],
        snackbar: false,
        timeout: 3000,
        birthdayMenus: null,
        tempItem:'',
        message: null,
        errorMessage: null,
        editedItem: {        
          name : 'New Patient',
          nationId : '',
          no : 'P0000000000',
          gender: '0',
          birthday: '1990-01-01'
        },
        condition: {
          page: 1,
          pgsz: 100, 
          birthday: null, 
          no: null, 
          name: null,
          nationId: null,
          gender: null,
        },
        xssRules: [
        value => {
          if ( this.condition.no == null) {
            return this.condition.no = ''
          } else {
            let xssFilter = new RegExp(/(\b)(on\S+)(\s*)=|javascript|(<\s*)(\/*)script/ig)
            this.condition.no = this.condition.no.replace(/[&><%^&"]/g, '')
            this.condition.no = (xssFilter.test(this.condition.no)) ? this.condition.no = '' : this.condition.no
            return true
          } 
        }],        
        toggleDataPicker: false,
      }
    },

    created () {
      this.search()
    },
    watch: {
      dialog() {
        this.editedItem.name = ''
        this.editedItem.nationId = ''
        this.editedItem.no = ''
        this.editedItem.gender = ''
        this.editedItem.birthday = ''
        this.$refs.form.resetValidation()
        this.errorMessage = null
      }
    },    
    computed: {
      ...mapGetters('patients', [
        'getPatientSearchResult',
        'getPatientSearchInfo'
      ])
    },
    methods: {
      ...mapActions('patients', [
        'searchPatients'
      ]),
      selectPatient(info) {
        if (info) {
          this.condition.no = info.no
          this.condition.gender = info.gender
          this.condition.name = info.name
          this.condition.nationId = info.nationId
        } else {
          this.condition.no = null
          this.condition.gender = null
          this.condition.name = null
          this.condition.nationId = null
        }        
      },
      search () {
        console.log('this.condition', this.condition)
        this.searchPatients(this.condition)
          .then(() => {
            this.initBirthdayMenus()
          })
      },
      resetSearch() {
        this.condition.no = null
        this.condition.gender = null
        this.condition.birthday = null
        this.condition.name = null
      },
      clickPage (page) {
        this.condition.page = page
        this.search()
      },
      changeSort (column) {
        if(column===''){return;}
        if (this.pagination.title === column) {
          this.pagination.order = !this.pagination.order
        } else {
          this.pagination.title = column
          this.pagination.order = false
        }
        this.$store.dispatch('patients/sortPatientSearchResult', {title:column,order:this.pagination.order})
      },

      initBirthdayMenus () {
        if (!this.getPatientSearchResult) {
          return
        }

        const patientsLen = this.getPatientSearchResult.length
        this.birthdayMenus = this.createArrayWithSameValue(patientsLen, false)
      },

      getGender (gender) {
        switch (Number(gender)) {
          case 0:
            return 'Female';
            break; // eslint-disable-line no-unreachable
          case 1:
            return 'Male';
            break; // eslint-disable-line no-unreachable
        }
      },

      createArrayWithSameValue: (size, item) => Array(size).fill(item),

      updatePatient ( patientID, field, value ) {
        
        for(let i=0;i<this.birthdayMenus.length;i++){
          this.birthdayMenus[i] = false
        }
        
        const updateObj = {
          patientID: patientID,
          field:field,
          value: value
        }

        this.$store.dispatch('patients/updateColumnById', updateObj)
         .then(res => {
            if (res && !res.success) {
              this.message = res.err.msg
              this.snackbar = true
            } else {
              this.message = null
              this.snackbar = false
            }
            this.search()
        });
      },

      async addPatient () {
        this.noLabel = this.editedItem.no == '' ? 'inline' : 'none'
        this.nameLabel = this.editedItem.name == '' ? 'inline' : 'none'
        this.nationIdLabel = this.editedItem.nationId == '' ? 'inline' : 'none'
        this.birthdayLabel = this.editedItem.birthday == '' ? 'inline' : 'none'

        if (this.noLabel == 'none' && this.nameLabel == 'none' && this.birthdayLabel == 'none' && this.nationIdLabel == 'none') {
          try {
            await this.$store.dispatch('patients/addPatient', this.editedItem)
            .then(res => {
              if (res && !res.success) {
                this.errorMessage = res.err.msg
              } else {
                this.errorMessage = null
                this.dialog = false;
                this.search()
              }
            });
          } catch (e) {
            console.log(e)
            this.dialog = false;
          }
        }
      },
      clickItem(data){
        this.tempItem = Object.assign({}, data);
      }
      
    }
  }
</script>

<style lang="scss">
.search-container{
  button {
    margin-top: 30px;
  }
}
</style>