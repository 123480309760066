import fetch from "node-fetch";
import servicesUtils from "./servicesUtils";
import common from "../utils/common";
import http from "./http-service";
import { isArray } from "lodash";

const API_URL = '/api';

const checkAndParse = common.compose(servicesUtils.parseJSON, servicesUtils.checkStatus)

export const dbServices = {
  async uploadPDF(payload) {
    try {
      const url =
        `${API_URL}/notificationUniGateUploadPDF/${payload.examinationID}?t=` +
        new Date().getTime().toString();

      const res = await fetch(url);
      const data = servicesUtils.checkStatus(res);

      return servicesUtils.parseJSON(data);
    } catch (e) {
      console.log(e);
    }
  },

  async notificationUniGateUploadScreenshot(payload) {
    try {
      const url =
        `${API_URL}/notificationUniGateUploadScreenshot/${payload.examinationID}?t=` +
        new Date().getTime().toString();

      const res = await fetch(url);
      const data = servicesUtils.checkStatus(res);

      return servicesUtils.parseJSON(data);
    } catch (e) {
      console.log(e);
    }
  },

  async searchExaminations({
    page,
    pgsz,
    st,
    et,
    accNo,
    patientID,
    orderDoctorID,
  }) {
    try {
      let condition = `page=${page}&pgsz=${pgsz}&st=${st}&et=${et}`;
      accNo && (condition += `&accNo=${accNo}`);
      patientID && (condition += `&patientID=${patientID}`);
      orderDoctorID && (condition += `&orderDoctorID=${orderDoctorID}`);
      let time = new Date().getTime().toString();

      const url = `${API_URL}/v2/examination/search?${condition}&t=${time}`;
      const res = await fetch(url);
      const data = servicesUtils.checkStatus(res);

      return servicesUtils.parseJSON(data);
    } catch (e) {
      console.log(e);
    }
  },

  async fetchExaminations(condition) {
    try {
      // const url = timeFrame? `${API_URL}/examinations/list/${timeFrame}?t=` + (new Date()).getTime().toString() : `${API_URL}/examinations/list?t=` + (new Date()).getTime().toString();

      const url =
        `${API_URL}/examinations/list?` +
        servicesUtils.queryParams(condition) +
        `&t=` +
        new Date().getTime().toString();
      const res = await fetch(url);
      const data = servicesUtils.checkStatus(res);

      return servicesUtils.parseJSON(data);
    } catch (e) {
      console.log(e);
    }
  },
  async fetchNewExaminations() {
    try {
      const url = `${API_URL}/worklist/b?t=` + new Date().getTime().toString();

      const res = await fetch(url);
      const data = servicesUtils.checkStatus(res);

      return servicesUtils.parseJSON(data);
    } catch (e) {
      console.log(e);
    }
  },
  async sendDICOMSR(examinationID) {
    try {
      const url =
        `${API_URL}/worklist/c/${examinationID}?t=` +
        new Date().getTime().toString();

      const res = await fetch(url);
      const data = servicesUtils.checkStatus(res);

      return servicesUtils.parseJSON(data);
    } catch (e) {
      console.log(e);
    }
  },
  async fetchExaminationInfos() {
    try {
      const url =
        `${API_URL}/examinations/infoList?t=` + new Date().getTime().toString();

      const res = await fetch(url);
      const data = servicesUtils.checkStatus(res);

      return servicesUtils.parseJSON(data);
    } catch (e) {
      console.log(e);
    }
  },

  async fetchExaminationDetailAndOtherList(payload) {
    try {
      const user = sessionStorage.getItem("user")
      const userObject = JSON.parse(user);
      const username = userObject.username;

      let baseUrl;
      if (Number(payload.accNo) != 0) {
        baseUrl =
          `${API_URL}/examinations/0/accNo/${payload.accNo}/detail/otherList?t=` +
          new Date().getTime().toString();
      } else {
        baseUrl =
          `${API_URL}/examinations/${payload.examinationID}/accNo/0/detail/otherList?t=` +
          new Date().getTime().toString();
      }

      const params = new URLSearchParams({
        t: new Date().getTime().toString(),
        username: username
      })

      const url = `${baseUrl}?${params.toString()}`
      const res = await fetch(url);
      const data = servicesUtils.checkStatus(res);

      return servicesUtils.parseJSON(data);
    } catch (e) {
      console.log(e);
    }
  },

  async fetchExaminationDetail(examinationID) {
    try {
      const url =
        `${API_URL}/examinations/${examinationID}/detail?t=` +
        new Date().getTime().toString();

      const res = await fetch(url);
      const data = servicesUtils.checkStatus(res);

      return servicesUtils.parseJSON(data);
    } catch (e) {
      console.log(e);
    }
  },
  async fetchFindings(examinationID) {
    try {
      const url =
        `${API_URL}/examinations/${examinationID}/findings?t=` +
        new Date().getTime().toString();
      const res = await fetch(url);
      const data = servicesUtils.checkStatus(res);

      return servicesUtils.parseJSON(data);
    } catch (e) {
      console.log(e);
    }
  },

  async fetchQuestionJSON(questionID) {
    try {
      const url =
        `${API_URL}/public/question-json-file/${questionID}.json?t=` +
        new Date().getTime().toString();
      const res = await fetch(url);
      const data = servicesUtils.checkStatus(res);

      return servicesUtils.parseJSON(data);
    } catch (e) {
      console.log(e);
    }
  },

  async updateExaminationStatus(id) {
    try {
      const params = {
        status: "1",
      };

      const url =
        `${API_URL}/examinations/update/status/${id}?t=` +
        new Date().getTime().toString();
      const res = await fetch(url, {
        method: "POST",
        body: JSON.stringify(params),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = servicesUtils.checkStatus(res);
      return servicesUtils.parseJSON(data);
    } catch (e) {
      console.log(e);
    }
  },
  async updateReportStatus(d) {
    try {
      const url =
        `${API_URL}/examinations/update/reportStatus/${d.id}?t=` +
        new Date().getTime().toString();
      const params = {
        reportStatus: d.value,
      };

      const res = await fetch(url, {
        method: "POST",
        body: JSON.stringify(params),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = servicesUtils.checkStatus(res);
      return servicesUtils.parseJSON(data);
    } catch (e) {
      console.log(e);
    }
  },
  async updateQuestionInput(d) {
    try {
      const user = sessionStorage.getItem("user")
      const userObject = JSON.parse(user);
      const username = userObject.username;

      const url =
        `${API_URL}/examinations/update/questionInput?t=` +
        new Date().getTime().toString();
      const params = {
        examination_typeID: d.examination_typeID,
        findingID: d.findingID,
        recordID: d.recordID,
        field: d.field,
        value: d.value,
        username: username
      };

      const res = await fetch(url, {
        method: "POST",
        body: JSON.stringify(params),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = servicesUtils.checkStatus(res);
      return servicesUtils.parseJSON(data);
    } catch (e) {
      console.log(e);
    }
  },
  async updateRecordInput(d) {
    try {
      const url =
        `${API_URL}/examinations/update/recordInput?t=` +
        new Date().getTime().toString();
      const params = {
        recordID: d.recordID,
        field: d.field,
        value: d.value,
      };

      const res = await fetch(url, {
        method: "POST",
        body: JSON.stringify(params),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = servicesUtils.checkStatus(res);
      return servicesUtils.parseJSON(data);
    } catch (e) {
      console.log(e);
    }
  },
  async updateExaminationColumn(d) {
    try {
      const params = {
        column: d.column,
        value: ['indication', 'clean_medID', 'antiplateletID', 'antispasmodicReason', 'complication', 'antispasmodicID']
          .includes(d.column) && isArray(d.value) ? d.value.join(',') : d.value,
      };

      const url =
        `${API_URL}/examinations/update/column/${d.id}?t=` +
        new Date().getTime().toString();
      const res = await fetch(url, {
        method: "POST",
        body: JSON.stringify(params),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = servicesUtils.checkStatus(res);
      return servicesUtils.parseJSON(data);
    } catch (e) {
      console.log(e);
    }
  },
  async addFinding(examinationID) {
    try {
      const user = sessionStorage.getItem("user")
      const userObject = JSON.parse(user);
      const username = userObject.username;
      
      const url =
        `${API_URL}/examinations/${examinationID}/finding/add?t=` +
        new Date().getTime().toString();
      const res = await fetch(url, {
        method: "POST",
        body: JSON.stringify({ username }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = servicesUtils.checkStatus(res);
      return servicesUtils.parseJSON(data);
    } catch (e) {
      console.log(e);
    }
  },
  async deleteFinding(findingID) {
    try {
      const url =
        `${API_URL}/examinations/${findingID}/finding/delete?t=` +
        new Date().getTime().toString();
      const res = await fetch(url, {
        method: "POST",
        body: JSON.stringify({}),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = servicesUtils.checkStatus(res);
      return servicesUtils.parseJSON(data);
    } catch (e) {
      console.log(e);
    }
  },
  async addExaminationSedative(examinationID) {
    try {
      const url =
        `${API_URL}/examinations/${examinationID}/sedative/add?t=` +
        new Date().getTime().toString();
      const res = await fetch(url, {
        method: "POST",
        body: JSON.stringify({}),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = servicesUtils.checkStatus(res);
      return servicesUtils.parseJSON(data);
    } catch (e) {
      console.log(e);
    }
  },
  async deleteExaminationSedative(examinationSedativeID) {
    try {
      const url =
        `${API_URL}/examinations/${examinationSedativeID}/sedative/delete?t=` +
        new Date().getTime().toString();
      const res = await fetch(url, {
        method: "POST",
        body: JSON.stringify({}),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = servicesUtils.checkStatus(res);
      return servicesUtils.parseJSON(data);
    } catch (e) {
      console.log(e);
    }
  },
  async updateExaminationSedativeColumn(d) {
    try {
      const params = {
        column: d.column,
        value: d.value,
      };

      const url =
        `${API_URL}/examinations/update/examinationSedativeColumn/${d.id}?t=` +
        new Date().getTime().toString();
      const res = await fetch(url, {
        method: "POST",
        body: JSON.stringify(params),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = servicesUtils.checkStatus(res);
      return servicesUtils.parseJSON(data);
    } catch (e) {
      console.log(e);
    }
  },
  async addExaminationLocation(recordID) {
    try {
      const url =
        `${API_URL}/examinations/${recordID}/location/add?t=` +
        new Date().getTime().toString();
      const res = await fetch(url, {
        method: "POST",
        body: JSON.stringify({}),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = servicesUtils.checkStatus(res);
      return servicesUtils.parseJSON(data);
    } catch (e) {
      console.log(e);
    }
  },
  async deleteExaminationLocation(examinationLocationID) {
    try {
      const url =
        `${API_URL}/examinations/${examinationLocationID}/location/delete?t=` +
        new Date().getTime().toString();
      const res = await fetch(url, {
        method: "POST",
        body: JSON.stringify({}),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = servicesUtils.checkStatus(res);
      return servicesUtils.parseJSON(data);
    } catch (e) {
      console.log(e);
    }
  },
  async updateExaminationLocationColumn(d) {
    try {
      const params = {
        column: d.column,
        value: d.value,
      };
      const url =
        `${API_URL}/examinations/update/examinationLocationColumn/${d.id}?t=` +
        new Date().getTime().toString();
      const res = await fetch(url, {
        method: "POST",
        body: JSON.stringify(params),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = servicesUtils.checkStatus(res);
      return servicesUtils.parseJSON(data);
    } catch (e) {
      console.log(e);
    }
  },

  async updateExaminationInfoColumn(obj) {
    try {
      const url =
        `${API_URL}/examinations/update?t=` + new Date().getTime().toString();
      const res = await fetch(url, {
        method: "POST",
        body: JSON.stringify(obj),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const result = await servicesUtils.parseJSON(res);
      return result;
    } catch (e) {
      console.log(e);
    }
  },
  async addExamination(obj) {
    try {
      // old api
      //const url = `${API_URL}/examinations/add?t=` + (new Date()).getTime().toString();
      let time = new Date().getTime().toString();
      const url = `${API_URL}/v2/examination/add?t=${time}`;

      const res = await fetch(url, {
        method: "POST",
        body: JSON.stringify(obj),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await servicesUtils.parseJSON(res);
      return result;
    } catch (e) {
      console.log(e);
    }
  },
  async writeRecordDiagnosis(recordID) {
    try {
      const url =
        `${API_URL}/tool/write-record-diagnosis/${recordID}?t=` +
        new Date().getTime().toString();
      const res = await fetch(url, {
        method: "PUT",
        body: JSON.stringify({}),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = servicesUtils.checkStatus(res);
      return servicesUtils.parseJSON(data);
    } catch (e) {
      console.log(e);
    }
  },
  async getOrderArticle (examinationID) {
    try {
      const url = `${API_URL}/examinations/${examinationID}/article?t=${new Date().getTime().toString()}`;
      const res = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await servicesUtils.parseJSON(res);
      return result;
    } catch (error) {
      // regardless
    }
  },
  async fetchDescriptiveParagraph(eid, etype) {
    const params = new URLSearchParams()
    params.append('eid', eid)
    params.append('etype', etype)
    try {
      const res = await fetch(`${API_URL}/examinations/descriptive/paragraph?${params}`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await servicesUtils.parseJSON(res);
      return result;
    } catch (error) {
      // regardless
    }
  },
  async updateDescriptiveParagraph(body) {
    try {
      const res = await fetch(`${API_URL}/examinations/descriptive/paragraph`, {
        headers: {
          "Content-Type": "application/json",
        },
        method: 'PUT',
        body: JSON.stringify(body),
      });
      const result = await servicesUtils.parseJSON(res);
      return result;
    } catch (error) {
      // regardless
    }
  },

  async sendEmail(eid, etype, email) {
    try {
        const params = new URLSearchParams({
          eid: eid,
          etype: etype
        })
        const body = {
          email: email
        }

        const res = await fetch(`${API_URL}/examinations/sendmail?${params}`,{
          headers: {
            "Content-Type": "application/json",
          },
          method: 'PUT',
          body: JSON.stringify(body),
        })
          if (!res.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();  // Assuming the server responds with JSON
        console.log('Mail sent success:', result);
    } catch (error) {
      console.log(error)
    }
  },
  async get_setting(body) {
    try {
      const res = await fetch(`${API_URL}/setting`, {
        headers: {
          "Content-Type": "application/json",
        },
        method: 'GET',
        body: JSON.stringify(body),
      });
      const result = await servicesUtils.parseJSON(res);
      return result;
    } catch (error) {
      // regardless
    }
  }
};

export const screenshotServices = {
  async setScreenshotGroup(d) {
    try {
      const url =
        `${API_URL}/image?t=` + new Date().getTime().toString();
      const res = await fetch(url, {
        method: "PUT",
        body: JSON.stringify(d),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = servicesUtils.checkStatus(res);
      return servicesUtils.parseJSON(data);
    } catch (e) {
      console.log(e);
    }
  },

  async setScreenshotStatus(d) {
    try {
      const params = {
        id: d,
      };

      const url =
        `${API_URL}/screenSetStatus?t=` + new Date().getTime().toString();
      const res = await fetch(url, {
        method: "POST",
        body: JSON.stringify(params),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = servicesUtils.checkStatus(res);
      return servicesUtils.parseJSON(data);
    } catch (e) {
      console.log(e);
    }
  },

  async deleteScreenshot(d) {
    try {
      const url = `${API_URL}/image/${d}?t=${new Date().getTime().toString()}`

      const res = await fetch(url, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = servicesUtils.checkStatus(res);
      return servicesUtils.parseJSON(data);
    } catch (e) {
      console.log(e);
    }
  },
};

export const organizationServices = {
  async fetchOrganizations() {
    try {
      const url =
        `${API_URL}/organizations/list?t=` + new Date().getTime().toString();
      const res = await fetch(url);
      const data = servicesUtils.checkStatus(res);

      return servicesUtils.parseJSON(data);
    } catch (e) {
      console.log(e);
    }
  },

  async addOrganization(obj) {
    try {
      const url =
        `${API_URL}/organizations/add?t=` + new Date().getTime().toString();
      const res = await fetch(url, {
        method: "POST",
        body: JSON.stringify(obj),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = servicesUtils.checkStatus(res);
      return servicesUtils.parseJSON(data);
    } catch (e) {
      console.log(e);
    }
  },

  async updateOrganizationColumn(obj) {
    try {
      const url =
        `${API_URL}/organizations/update?t=` + new Date().getTime().toString();
      const res = await fetch(url, {
        method: "POST",
        body: JSON.stringify(obj),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = servicesUtils.checkStatus(res);
      return servicesUtils.parseJSON(data);
    } catch (e) {
      console.log(e);
    }
  },
};

export const doctorServices = {
  async searchDoctor({ keyword }) {
    let xssFilter = new RegExp(
      /(\b)(on\S+)(\s*)=|javascript|(<\s*)(\/*)script/gi
    );
    try {
      let kwXssPrevent = xssFilter.test(keyword)
        ? (kwXssPrevent = "")
        : keyword;
      kwXssPrevent = kwXssPrevent.replace(/[<-[\]/{}()*+?&.\\^$|]/g, "");
      const url =
        `${API_URL}/v2/autoCompelete/doctor/name?kw=${kwXssPrevent}&t=` +
        new Date().getTime().toString();
      const res = await fetch(url);
      const data = servicesUtils.checkStatus(res);

      return servicesUtils.parseJSON(data);
    } catch (e) {
      console.log(e);
    }
  },

  async fetchDoctors() {
    try {
      const url =
        `${API_URL}/doctors/list?t=` + new Date().getTime().toString();
      const res = await fetch(url);
      const data = servicesUtils.checkStatus(res);

      return servicesUtils.parseJSON(data);
    } catch (e) {
      console.log(e);
    }
  },

  async addDoctor(obj) {
    try {
      const url = `${API_URL}/doctors/add?t=` + new Date().getTime().toString();
      const res = await fetch(url, {
        method: "POST",
        body: JSON.stringify(obj),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = servicesUtils.checkStatus(res);
      return servicesUtils.parseJSON(data);
    } catch (e) {
      console.log(e);
    }
  },

  async updateDoctorColumn(obj) {
    try {
      const url =
        `${API_URL}/doctors/update?t=` + new Date().getTime().toString();
      const res = await fetch(url, {
        method: "POST",
        body: JSON.stringify(obj),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = servicesUtils.checkStatus(res);
      return servicesUtils.parseJSON(data);
    } catch (e) {
      console.log(e);
    }
  },
};

export const landmarkServices = {
  async fetchLandmarks() {
    try {
      const url =
        `${API_URL}/landmarks/list?t=` + new Date().getTime().toString();
      const res = await fetch(url);
      const data = servicesUtils.checkStatus(res);

      return servicesUtils.parseJSON(data);
    } catch (e) {
      console.log(e);
    }
  },

  async addLandmark(obj) {
    try {
      const url =
        `${API_URL}/landmarks/add?t=` + new Date().getTime().toString();
      const res = await fetch(url, {
        method: "POST",
        body: JSON.stringify(obj),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = servicesUtils.checkStatus(res);
      return servicesUtils.parseJSON(data);
    } catch (e) {
      console.log(e);
    }
  },

  async updateLandmarkColumn(obj) {
    try {
      const url =
        `${API_URL}/landmarks/update?t=` + new Date().getTime().toString();
      const res = await fetch(url, {
        method: "POST",
        body: JSON.stringify(obj),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = servicesUtils.checkStatus(res);
      return servicesUtils.parseJSON(data);
    } catch (e) {
      console.log(e);
    }
  },
};

export const sedativesServices = {
  async fetchSedatives() {
    try {
      const url =
        `${API_URL}/sedatives/list?t=` + new Date().getTime().toString();
      const res = await fetch(url);
      const data = servicesUtils.checkStatus(res);

      return servicesUtils.parseJSON(data);
    } catch (e) {
      console.log(e);
    }
  },

  async addSedative(obj) {
    try {
      const url =
        `${API_URL}/sedatives/add?t=` + new Date().getTime().toString();
      const res = await fetch(url, {
        method: "POST",
        body: JSON.stringify(obj),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = servicesUtils.checkStatus(res);
      return servicesUtils.parseJSON(data);
    } catch (e) {
      console.log(e);
    }
  },

  async updateSedativeColumn(obj) {
    try {
      const url =
        `${API_URL}/sedatives/update?t=` + new Date().getTime().toString();
      const res = await fetch(url, {
        method: "POST",
        body: JSON.stringify(obj),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = servicesUtils.checkStatus(res);
      return servicesUtils.parseJSON(data);
    } catch (e) {
      console.log(e);
    }
  },
};

export const examinationTypeServices = {
  async fetchExaminationTypes() {
    try {
      const url =
        `${API_URL}/examinationTypes/list?t=` + new Date().getTime().toString();
      const res = await fetch(url);
      const data = servicesUtils.checkStatus(res);

      return servicesUtils.parseJSON(data);
    } catch (e) {
      console.log(e);
    }
  },

  async addExaminationType(obj) {
    try {
      const url =
        `${API_URL}/examinationTypes/add?t=` + new Date().getTime().toString();
      const res = await fetch(url, {
        method: "POST",
        body: JSON.stringify(obj),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = servicesUtils.checkStatus(res);
      return servicesUtils.parseJSON(data);
    } catch (e) {
      console.log(e);
    }
  },

  async updateExaminationTypeColumn(obj) {
    try {
      const url =
        `${API_URL}/examinationTypes/update?t=` +
        new Date().getTime().toString();
      const res = await fetch(url, {
        method: "POST",
        body: JSON.stringify(obj),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = servicesUtils.checkStatus(res);
      return servicesUtils.parseJSON(data);
    } catch (e) {
      console.log(e);
    }
  },
};

export const employeeTypeServices = {
  async fetchEmployeeTypes() {
    try {
      const url =
        `${API_URL}/employeeTypes/list?t=` + new Date().getTime().toString();
      const res = await fetch(url);
      const data = servicesUtils.checkStatus(res);

      return servicesUtils.parseJSON(data);
    } catch (e) {
      console.log(e);
    }
  },

  async addEmployeeType(obj) {
    try {
      const url =
        `${API_URL}/employeeTypes/add?t=` + new Date().getTime().toString();
      const res = await fetch(url, {
        method: "POST",
        body: JSON.stringify(obj),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = servicesUtils.checkStatus(res);
      return servicesUtils.parseJSON(data);
    } catch (e) {
      console.log(e);
    }
  },

  async updateEmployeeTypeColumn(obj) {
    try {
      const url =
        `${API_URL}/employeeTypes/update?t=` + new Date().getTime().toString();
      const res = await fetch(url, {
        method: "POST",
        body: JSON.stringify(obj),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = servicesUtils.checkStatus(res);
      return servicesUtils.parseJSON(data);
    } catch (e) {
      console.log(e);
    }
  },
};

export const userServices = {
  async fetchUsers() {
    try {
      const url = `${API_URL}/users/list?t=` + new Date().getTime().toString();
      const res = await fetch(url);
      const data = servicesUtils.checkStatus(res);

      return servicesUtils.parseJSON(data);
    } catch (e) {
      console.log(e);
    }
  },

  async addUser(obj) {
    try {
      const url = `${API_URL}/users/add?t=` + new Date().getTime().toString();
      const res = await fetch(url, {
        method: "POST",
        body: JSON.stringify(obj),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = servicesUtils.checkStatus(res);
      return servicesUtils.parseJSON(data);
    } catch (e) {
      console.log(e);
    }
  },

  async updateUserColumn(obj) {
    try {
      const url =
        `${API_URL}/users/update?t=` + new Date().getTime().toString();
      const res = await fetch(url, {
        method: "POST",
        body: JSON.stringify(obj),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = servicesUtils.checkStatus(res);
      return servicesUtils.parseJSON(data);
    } catch (e) {
      throw (e)
      //   console.log(e);
    }
  },
};

export const patientServices = {
  async searchPatient({ keyword }) {
    let xssFilter = new RegExp(
      /(\b)(on\S+)(\s*)=|javascript|(<\s*)(\/*)script/gi
    );
    try {
      let kwXssPrevent = xssFilter.test(keyword)
        ? (kwXssPrevent = "")
        : keyword;
      kwXssPrevent = kwXssPrevent.replace(/[<-[\]/{}()*+?&.\\^$|]/g, "");
      let time = new Date().getTime().toString();
      const url = `${API_URL}/v2/autoCompelete/patient/name?kw=${kwXssPrevent}&t=${time}`;
      const res = await fetch(url);
      const data = servicesUtils.checkStatus(res);

      return servicesUtils.parseJSON(data);
    } catch (e) {
      console.log(e);
    }
  },

  async searchPatients({ page, pgsz, birthday, no, name, gender, nationId }) {
    try {
      let condition = `page=${page}&pgsz=${pgsz}`;
      birthday && (condition += `&birthday=${birthday}`);
      no && (condition += `&no=${no}`);
      name && (condition += `&name=${name}`);
      gender && (condition += `&gender=${gender}`);
      nationId && (condition += `&nationId=${nationId}`);
      let time = new Date().getTime().toString();

      const url = `${API_URL}/v2/patient/search?${condition}&t=${time}`;
      const res = await fetch(url);
      const data = servicesUtils.checkStatus(res);

      return servicesUtils.parseJSON(data);
    } catch (e) {
      console.log(e);
    }
  },

  async fetchPatients() {
    try {
      const url =
        `${API_URL}/patients/list?t=` + new Date().getTime().toString();
      const res = await fetch(url);
      const data = servicesUtils.checkStatus(res);

      return servicesUtils.parseJSON(data);
    } catch (e) {
      console.log(e);
    }
  },

  async addPatient(obj) {
    try {
      const url =
        `${API_URL}/v2/patient/add?t=` + new Date().getTime().toString();
      const res = await fetch(url, {
        method: "POST",
        body: JSON.stringify(obj),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const result = await servicesUtils.parseJSON(res);
      return result;
    } catch (e) {
      console.log(e);
    }
  },

  async updatePatientColumn(obj) {
    try {
      const url =
        `${API_URL}/patients/update?t=` + new Date().getTime().toString();
      const res = await fetch(url, {
        method: "POST",
        body: JSON.stringify(obj),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const result = await servicesUtils.parseJSON(res);
      return result;
    } catch (e) {
      console.log(e);
    }
  },
};

export const technicalStaffsServices = {
  async fetchTechnicalStaffs() {
    try {
      const url =
        `${API_URL}/technicalStaffs/list?t=` + new Date().getTime().toString();
      const res = await fetch(url);
      const data = servicesUtils.checkStatus(res);

      return servicesUtils.parseJSON(data);
    } catch (e) {
      console.log(e);
    }
  },

  async addTechnicalStaff(obj) {
    try {
      const url =
        `${API_URL}/technicalStaffs/add?t=` + new Date().getTime().toString();
      const res = await fetch(url, {
        method: "POST",
        body: JSON.stringify(obj),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = servicesUtils.checkStatus(res);
      return servicesUtils.parseJSON(data);
    } catch (e) {
      console.log(e);
    }
  },

  async updateTechnicalStaffColumn(obj) {
    try {
      const url =
        `${API_URL}/technicalStaffs/update?t=` +
        new Date().getTime().toString();
      const res = await fetch(url, {
        method: "POST",
        body: JSON.stringify(obj),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = servicesUtils.checkStatus(res);
      return servicesUtils.parseJSON(data);
    } catch (e) {
      console.log(e);
    }
  },
};

export const inspectionInstrumentsServices = {
  async fetchInspectionInstruments() {
    try {
      const url =
        `${API_URL}/inspectionInstruments/list?t=` +
        new Date().getTime().toString();
      const res = await fetch(url);
      const data = servicesUtils.checkStatus(res);

      return servicesUtils.parseJSON(data);
    } catch (e) {
      console.log(e);
    }
  },

  async addInspectionInstrument(obj) {
    try {
      const url =
        `${API_URL}/inspectionInstruments/add?t=` +
        new Date().getTime().toString();
      const res = await fetch(url, {
        method: "POST",
        body: JSON.stringify(obj),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = servicesUtils.checkStatus(res);
      return servicesUtils.parseJSON(data);
    } catch (e) {
      console.log(e);
    }
  },

  async updateInspectionInstrumentColumn(obj) {
    try {
      const url =
        `${API_URL}/inspectionInstruments/update?t=` +
        new Date().getTime().toString();
      const res = await fetch(url, {
        method: "POST",
        body: JSON.stringify(obj),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = servicesUtils.checkStatus(res);
      return servicesUtils.parseJSON(data);
    } catch (e) {
      console.log(e);
    }
  },
};

export const washInstrumentServices = {
  async fetchWashInstruments() {
    try {
      const url =
        `${API_URL}/washInstruments/list?t=` + new Date().getTime().toString();
      const res = await fetch(url);
      const data = servicesUtils.checkStatus(res);

      return servicesUtils.parseJSON(data);
    } catch (e) {
      console.log(e);
    }
  },

  async addWashInstrument(obj) {
    try {
      const url =
        `${API_URL}/washInstruments/add?t=` + new Date().getTime().toString();
      const res = await fetch(url, {
        method: "POST",
        body: JSON.stringify(obj),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = servicesUtils.checkStatus(res);
      return servicesUtils.parseJSON(data);
    } catch (e) {
      console.log(e);
    }
  },

  async updateWashInstrumentColumn(obj) {
    try {
      const url =
        `${API_URL}/washInstruments/update?t=` +
        new Date().getTime().toString();
      const res = await fetch(url, {
        method: "POST",
        body: JSON.stringify(obj),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = servicesUtils.checkStatus(res);
      return servicesUtils.parseJSON(data);
    } catch (e) {
      console.log(e);
    }
  },
};

export const cleanMedServices = {
  async fetchCleanMeds() {
    try {
      const url =
        `${API_URL}/cleanMeds/list?t=` + new Date().getTime().toString();
      const res = await fetch(url);
      const data = servicesUtils.checkStatus(res);

      return servicesUtils.parseJSON(data);
    } catch (e) {
      console.log(e);
    }
  },

  async addCleanMed(obj) {
    try {
      const url =
        `${API_URL}/cleanMeds/add?t=` + new Date().getTime().toString();
      const res = await fetch(url, {
        method: "POST",
        body: JSON.stringify(obj),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = servicesUtils.checkStatus(res);
      return servicesUtils.parseJSON(data);
    } catch (e) {
      console.log(e);
    }
  },

  async updateCleanMedColumn(obj) {
    try {
      const url =
        `${API_URL}/cleanMeds/update?t=` + new Date().getTime().toString();
      const res = await fetch(url, {
        method: "POST",
        body: JSON.stringify(obj),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = servicesUtils.checkStatus(res);
      return servicesUtils.parseJSON(data);
    } catch (e) {
      console.log(e);
    }
  },
};

export const antiplateletServices = {
  async fetchAntiplatelets() {
    try {
      const url =
        `${API_URL}/antiplatelets/list?t=` + new Date().getTime().toString();
      const res = await fetch(url);
      const data = servicesUtils.checkStatus(res);

      return servicesUtils.parseJSON(data);
    } catch (e) {
      console.log(e);
    }
  },

  async addAntiplatelet(obj) {
    try {
      const url =
        `${API_URL}/antiplatelets/add?t=` + new Date().getTime().toString();
      const res = await fetch(url, {
        method: "POST",
        body: JSON.stringify(obj),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = servicesUtils.checkStatus(res);
      return servicesUtils.parseJSON(data);
    } catch (e) {
      console.log(e);
    }
  },

  async updateAntiplateletColumn(obj) {
    try {
      const url =
        `${API_URL}/antiplatelets/update?t=` + new Date().getTime().toString();
      const res = await fetch(url, {
        method: "POST",
        body: JSON.stringify(obj),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = servicesUtils.checkStatus(res);
      return servicesUtils.parseJSON(data);
    } catch (e) {
      console.log(e);
    }
  },
};

export const antispasmodicServices = {
  async fetchAntispasmodics() {
    try {
      const url =
        `${API_URL}/antispasmodics/list?t=` + new Date().getTime().toString();
      const res = await fetch(url);
      const data = servicesUtils.checkStatus(res);

      return servicesUtils.parseJSON(data);
    } catch (e) {
      console.log(e);
    }
  },

  async addAntispasmodic(obj) {
    try {
      const url =
        `${API_URL}/antispasmodics/add?t=` + new Date().getTime().toString();
      const res = await fetch(url, {
        method: "POST",
        body: JSON.stringify(obj),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = servicesUtils.checkStatus(res);
      return servicesUtils.parseJSON(data);
    } catch (e) {
      console.log(e);
    }
  },

  async updateAntispasmodicColumn(obj) {
    try {
      const url =
        `${API_URL}/antispasmodics/update?t=` + new Date().getTime().toString();
      const res = await fetch(url, {
        method: "POST",
        body: JSON.stringify(obj),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = servicesUtils.checkStatus(res);
      return servicesUtils.parseJSON(data);
    } catch (e) {
      console.log(e);
    }
  },
};

export const reportServices = {
  async fetchChineseReport(obj) {
    try {
      const url = `${API_URL}/report/${obj.language}?examinationID=${obj.examinationID}`;
      const res = await fetch(url);
      const data = servicesUtils.checkStatus(res);
      return servicesUtils.parseJSON(data);
    } catch (e) {
      console.log(e);
    }
  },

  async fetchQualityReport(obj) {
    try {
      const url = `${API_URL}/report/quality?examinationID=${obj.examinationID}`;
      const res = await fetch(url);
      const data = servicesUtils.checkStatus(res);
      return servicesUtils.parseJSON(data);
    } catch (e) {
      console.log(e);
    }
  },

  async uploadHISReport(obj) {
    try {
      const url = `${API_URL}/report/his?examinationId=${obj.examinationId}&worklistId=${obj.worklistId}`;
      const res = await fetch(url);
      const data = servicesUtils.checkStatus(res);
      return servicesUtils.parseJSON(data);
    } catch (e) {
      console.log(e);
    }
  },

  async uploadHPAReport(obj) {
    try {
      const url = `${API_URL}/report/hpa?examinationId=${obj.examinationId}&worklistId=${obj.worklistId}`;
      const res = await fetch(url);
      const data = servicesUtils.checkStatus(res);
      return servicesUtils.parseJSON(data);
    } catch (e) {
      console.log(e);
    }
  },

  async sendSRReport (examinationID) {
    try {
      const url =
        `${API_URL}/examinations/sendReport?t=` +
        new Date().getTime().toString();

      const res = await fetch(url, {
        method: "POST",
        body: JSON.stringify({ examinationID }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = servicesUtils.checkStatus(res);
      return servicesUtils.parseJSON(data);
    } catch (e) {
      console.log(e);
    }
  },

  async transferToNewAccNo(obj) {
    try {
      const url = `/report/transfer/patient/${obj.patientId}/acc/${obj.accNo}`;
      console.log("transferToNewAccNo =====> ", url);
      const res = await http.fetch(url, {
        method: "POST",
        body: JSON.stringify({
          newAccNo: obj.newAccNo,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      return res.data;
    } catch (e) {
      console.log(e);
      return e;
    }
  },
  async fetchReportTime(eid) {
    try {
      return await fetch(`${API_URL}/report/time/${eid}`, {
        method: 'GET',
        headers: { "Content-Type": "application/json" },
      }).then(checkAndParse)
    } catch (e) {
      console.log(e)
    }
  },
  async updateReportTime(eid, reporting_time) {
    try {
      return await fetch(`${API_URL}/report/setTime/${eid}`, {
        method: 'PUT',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ reporting_time })
      }).then(checkAndParse)
    } catch (e) {
      console.log(e)
    }
  },
};

export const analyticsServices = {
  async fetchSearchFinding() {
    try {
      const { data } = await fetch(`${API_URL}/analytics/search/finding`).then(
        checkAndParse
      );
      return data;
    } catch (e) {
      console.log(e);
    }
  },
  async fetchSearchLocation() {
    try {
      const { data } = await fetch(`${API_URL}/analytics/search/location`).then(
        checkAndParse
      );
      return data;
    } catch (e) {
      console.log(e);
    }
  },
  async fetchSearchIntervention() {
    try {
      const { data } = await fetch(
        `${API_URL}/analytics/search/intervention`
      ).then(checkAndParse);
      return data;
    } catch (e) {
      console.log(e);
    }
  },
  async search(params) {
    try {
      const { data } = await fetch(`${API_URL}/analytics/search`, {
        method: "POST",
        body: JSON.stringify(params),
        headers: {
          "Content-Type": "application/json",
        },
      }).then(checkAndParse);
      return data;
    } catch (e) {
      console.log(e);
    }
  },
  async queryPDR(params) {
    try {
      const { data } = await fetch(`${API_URL}/analytics/pdr`, {
        method: "POST",
        body: JSON.stringify(params),
        headers: {
          "Content-Type": "application/json",
        },
      }).then(checkAndParse);
      return data;
    } catch (e) {
      console.log(e);
    }
  },
  async queryWithdrawTime(params) {
    try {
      const { data } = await fetch(`${API_URL}/analytics/withdrawTime`, {
        method: "POST",
        body: JSON.stringify(params),
        headers: {
          "Content-Type": "application/json",
        },
      }).then(checkAndParse);
      return data;
    } catch (e) {
      console.log(e);
    }
  },
};


export const ttsServices = {
  async getAudioFeedBack (commands, url) {
    try {
      const params = {
        commands,
        "voice": "en-US-Neural2-E"
      };
      const res = await fetch(url, {
        method: "POST",
        body: JSON.stringify(params),
        headers: {
          "Content-Type": "application/json",
        },
      }).then(res => {
        return res.blob()
      })
      return res

      // const data = servicesUtils.checkStatus(res);
      // return servicesUtils.parseJSON(data);
    } catch (e) {
      console.log(e);
    }
  }
}

export const asrService = { 
  async speechRecognition(params) {
    try {
      const data = await fetch(`${API_URL}/speech-recognition`, {
        method: 'POST',
        body: JSON.stringify(params),
        headers: {
            "Content-Type": "application/json",
        },
      }).then(checkAndParse);
      return data;
    } catch (e) {
      console.log(e);
    }
  },
  async saveFrontendLog (frontEndLog) {
    try {
      const data = await fetch(`${API_URL}/frontEndLog`, {
        method: 'POST',
        body: JSON.stringify({frontEndLog}),
        headers: {
            "Content-Type": "application/json",
        },
      }).then(checkAndParse);
      return data;
    } catch (e) {
      console.log(e);
    }
  }
}